import Phaser from "phaser";
import { GlobalConfig, WINDOW_WIDTH } from '../GlobalConfig';

const DEFAULT_SPEED = 200;
const MAX_SPEED = 500;

// Player represent our main character sprite
//    - load sprites
//    - expose convenience methods
//    - have internal id for multitouch identifications
//
export class Player extends Phaser.Physics.Arcade.Sprite {
  // event handler - when Player reaching `toGetTo` position
  onReachingPosition;
  // flag that said the player is walking (so do not override with INPUT event)
  isWalking;

  constructor(scene, x, y, prefix) {
    let frame = 0;
    if (prefix == "neng") {
      frame = 0;
    } else {
      frame = 20;
    }
    super(scene, x, y, "nengAkang");

    // center-point
    this.originX = 0.5;
    this.originY = 1;
    this.tween = null;
    this.curve = null;

    this.id = null;
    this.scene = scene;

    this.state = 'stop';
    this.idleCounter = 0;

    // sprite prefix 
    this.prefix = prefix;

    this.tx = x;
    this.ty = y;

    this.speed = DEFAULT_SPEED;
    // maximum speed to achieve
    this.maxSpeed = MAX_SPEED;
    // if there's any object to follow?
    this.toFollow = null;
    // if there's a position to get to
    this.toGetTo = null;

    // add self to scene and physics tracker
    scene.add.existing(this);
    scene.physics.add.existing(this);
    let bW = this.body.width;
    let bH = this.body.height;
    this.setBodySize(bW / 2, bH / 10, false);
    this.setOffset(bW / 2 - 50, bH - 30);

    // player cannot exceed world bounds
    this.setCollideWorldBounds(true, 0.2, 0.2);
    this.setPushable(true);

    // input debugging
    if (GlobalConfig.debug) {
      scene.input.enableDebug(this);
    }
    this.play(this.prefix + "Down");
    this.stop();
  }

  // setToGetTo - set target x,y and what to do when reaching the position
  setToGetTo(x, y, event) {
    this.isWalking = true;
    this.toGetTo = {x:x, y:y};
    this.onReachingPosition = event;
  }

  update(time, deltaTime) {

    this.depth = this.y;

    // either need to follow object or there's a position to get to
    let targetX = null;
    let targetY = null;
    if (this.toFollow != null || this.toGetTo != null) {
      let distance;
      let dx;
      let dy;
      
      if (this.toFollow != null) {
        targetX = this.toFollow.x;
        targetY = this.toFollow.y;
        distance = this.maxSpeed;
        this.isWalking = true;
      } else if (this.toGetTo != null) {
        targetX = this.toGetTo.x;
        targetY = this.toGetTo.y;
        distance = 50;
        this.isWalking = true;
      }
      // get distance between us - move based on distance diff
      dx = targetX - this.x;
      dy = targetY - this.y;
      var aDx = Math.abs(dx);
      var aDy = Math.abs(dy);

      if (aDx < distance) {
        aDx = 0;
      }
      if (aDy < distance) {
        aDy = 0;
      }

      if (aDx == 0 && aDy == 0) {
        // reaching position - reset target - call the distance event
        if (this.toGetTo != null) {
          this.moveStop();          
          if (this.onReachingPosition != null) {
            this.onReachingPosition(this);            
          }
          this.toGetTo = null;
          this.onReachingPosition = null;
          this.isWalking = false;
        }
      }

      // check if we are to far from target
      if (aDx > distance) {
        if (targetX < this.x) {
          this.moveLeft();
        } else if (targetX > this.x) {
          this.moveRight();
        }
      }
      if (aDy > distance) {
        if (targetY < this.y) {
          this.moveUp();
        } else if (targetY > this.y)
          this.moveDown();
      }
    }
  }

  // cleanup - destroy the sprite
  cleanup() {
    //
    // the effect is done on scene level
    //
    //this.scene.smallExplSfx.play();
    //this.scene.spawnShipExplosion(this.x, this.y);
    this.active = false;
    this.setVisible(false);
    this.body.destroy();
  }

  // setNetState to set target x,y 
  setNetState(x, y) {
    this.tx = x;
    this.ty = y;
  }

  // set the Player `id`
  setId(id) {
    this.id = id;
  }

  // Interpolate tx,ty to x,y based on netDelta and deltaTime
  interpolatePos(netDelta, deltaTime) {
    var dx = this.tx - this.x;
    var dy = this.ty - this.y;
    var adx = Math.abs(dx);
    var ady = Math.abs(dy);
    var vx = dx / (netDelta / deltaTime);
    var vy = dy / (netDelta / deltaTime);

    // if the distance is too great - just teleport to that position
    if (adx >= 50 || adx >= 50) {
      this.x = this.tx;
      this.y = this.ty;
    } else {
      // cleanly interpolate
      this.x += vx;
      this.y += vy;
    }
  }

  facePointTo(direction) {
    this.play(this.prefix + direction, true);
  }

  moveUp() {
    this.bumpSpeed();
    this.setVelocityY(-this.speed);
    this.facePointTo("Up");
  }

  moveDown() {
    this.bumpSpeed();
    this.setVelocityY(this.speed);
    this.facePointTo("Down");
  }

  moveLeft() {
    this.bumpSpeed();
    this.setVelocityX(-this.speed);
    this.facePointTo("Left");
  }

  moveRight() {
    this.bumpSpeed();
    this.setVelocityX(this.speed);
    this.facePointTo("Right");
  }

  moveStop() {
    // when we are stopping - idle counter is incremented
    this.idleCounter += 1;
    if (this.idleCounter > 100) {
      this.idleCounter = 100;
      this.state = 'idle';
      // we are idle 
      this.facePointTo("Down");
      this.stop();
    }

    this.speed = DEFAULT_SPEED;
    this.setVelocity(0, 0);
    this.stop();
  }

  bumpSpeed() {
    // when speed is bumped - idle counter is reset
    this.idleCounter = 0;
    // the user state is also not idle
    this.state = 'running';

    // speed!
    this.speed += 30;
    this.speed = Math.min(this.speed, this.maxSpeed);
  }

  follow(toFollow) {
    this.maxSpeed = 200;
    this.toFollow = toFollow;
  }
}
