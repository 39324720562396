import { WalkerGame } from "./WalkerGame";

if (typeof window.demo !== "undefined" && window.demo == "demo") {
    // auto load if we are on `demo` mode
    document.querySelector("#game").innerHTML = "";
    window.WalkerGame = new WalkerGame({
        
    });
}

// experiment the global function - to bypass commonJs require
window.initWalkerGame = function (options) {
    window.WalkerGame = new WalkerGame(options);
    return window.WalkerGame;
}

window.destroyWalkerGame = function () {
    window.WalkerGame.destroy();
    window.WalkerGame = null;
}

