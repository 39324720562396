import Phaser from "phaser";
import { GlobalConfig } from '../GlobalConfig';

// Doodad represent elements
export class Doodad extends Phaser.Physics.Arcade.Sprite {

  // event handler when doodad is clicked
  onClick;

  constructor(scene, element, player) {
    // element is based on the content.json structure
    super(scene, element.x, element.y, "atlas");

    scene.add.existing(this);
    if (element.block === true) {
      // collider
      scene.physics.add.existing(this);
      this.setImmovable(true);

      let bW = this.body.width;
      let bH = this.body.height;
      //this.setOrigin(0.5, 0.5);
      //this.setCircle(100, bW / 2, bH/2);
    }
    this.setFrame(element.sprite);
    console.log(element.action, element.action !== "");
    if (element.action !== "") {
      this.setInteractive();
      this.on('pointerdown', () => {
        if (this.onClick != null) {
          // onCLick event              
          // pass the Doodad object, `Game` reff - the element and the element action
          this.onClick(this, scene, element, element.action);
        }
      });      
    }

    if (typeof element.scale != "undefined") {
      this.setScale(element.scale);
    }

    scene.physics.add.collider(player, this);    

    this.depth = element.z;
  }

  clean() {

  }
}