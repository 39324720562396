import Phaser from "phaser";
import { GlobalConfig, GAME_WIDTH, GAME_HEIGHT } from './GlobalConfig';
import { BaseScene } from "./BaseScene";

class SceneLoader extends BaseScene {

  constructor(...args) {
    super(...args);
  }

  preload() {
    // add a sprite that uses loadingbar asset,
    // that was quickly loaded during the `Boot` scene
    // this.loadSprite = this.add.sprite(0, 0, 'loadingbar');
    // this.loadSprite.width = 1;
    // this.loadSprite.x = this.cameras.main.centerX;
    // this.loadSprite.y = 300;
    // this.loadSprite.setScale(1,2);

    this.loadAllResources();

    // loading text
    this.loaderText = this.add.dynamicBitmapText(this.cameras.main.centerX, GAME_HEIGHT / 2, 'leFont', "Loading...", 30);
    this.loaderText.setOrigin(0.5, 0.5);

    // loading event handler
    this.load.on('progress', this.progressDraw, { scene: this.scene, loadSprite: this.loadSprite, cameras: this.cameras });
    this.load.on('complete', this.progressDone, { scene: this.scene });
  }

  progressDraw(percentage) {
    // update the sprite size to reflect loading percentage
    // this.loadSprite.width = this.cameras.main.width * (percentage / 100);
    // this.loadSprite.x = this.cameras.main.centerX - this.loadSprite.width / 2;
    let per = Math.round(percentage * 100);
    this.scene.scene.loaderText.text = per + "%";
  }

  progressDone() {
    // complete loading everything - go to next scene
    var nextScene = "SceneUnlocker";
    if (GlobalConfig.startScene == "SceneCredits") {
      nextScene = GlobalConfig.startScene;
    }
    if (GlobalConfig.startScene == "ScenePreview") {
      nextScene = GlobalConfig.startScene;
    }
    if (GlobalConfig.startScene == "SceneStage") {
      nextScene = GlobalConfig.startScene;
    }
    if (GlobalConfig.startScene == "SceneStageAbuse") {
      nextScene = GlobalConfig.startScene;
    }

    this.scene.start(nextScene);
  }

  loadAllResources() {
    const assetsUrl = GlobalConfig.assetsUrl;
    this.load.crossOrigin = "anonymous";

    console.log("SceneLoader");

    //------ let the heavy loading begin

    // bitmap font
    let fontUrl = `${assetsUrl}/leFont.png`;
    let fontXml = `${assetsUrl}/leFont.xml`;
    // let fontUrl = new URL("/assets/leFont.png", import.meta.url);
    // let fontXml = new URL("/assets/leFont.xml", import.meta.url);
    this.load.bitmapFont("leFont", fontUrl, fontXml);

    // load atlas
    this.load.multiatlas('atlas', `${assetsUrl}/atlas2.png.json`, 'assets');

    // load content json
    this.load.json("content", `${assetsUrl}/content.json`);

    // load neng-akang spritesheet
    this.load.spritesheet('nengAkang', `${assetsUrl}/neng-akang-2x.png`, {
      frameWidth: 4000 / 20,
      frameHeight: 200,
    });

    // the huge ass background
    this.load.image("bg", `${assetsUrl}/peta.webp`);
  }

  create() {
    super.create();

    // boxing
    this.boxGraphic = this.add.graphics();
    this.boxGraphic.fillStyle(0x000000, 1);
    this.boxGraphic.fillRect(0, 0, GAME_WIDTH, GAME_HEIGHT);
  }
}

export { SceneLoader };
