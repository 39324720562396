import Phaser from "phaser";
import { GlobalConfig, GAME_WIDTH, GAME_HEIGHT, WINDOW_WIDTH, WINDOW_HEIGHT } from './GlobalConfig';
import { Doodad } from "./objects/Doodad";

class BaseScene extends Phaser.Scene {

  constructor(...args) {
    super(...args);
  }

  init() {
    this.epochOffset = 1617580800000;

    this.worldTick = 0;
    this.worldStart = 0;
  }

  // openLightbox(action) {
  //   let lightbox = glightbox({
  //      elements : [
  //       {
  //         'href' : action,
  //         'type' : 'external',
  //       }
  //      ]
  //   });
  //   lightbox.open();
  // }

  create() {
    const width = this.scale.gameSize.width;
    const height = this.scale.gameSize.height;
    this.parent = new Phaser.Structs.Size(width, height);
    this.sizer = new Phaser.Structs.Size(GAME_WIDTH, GAME_HEIGHT, Phaser.Structs.Size.FIT, this.parent);
    this.parent.setSize(width, height);
    this.sizer.setSize(width, height);
    this.updateCamera();
    this.scale.on('resize', this.resize, this);

    // see https://www.khronos.org/webgl/wiki/HandlingContextLost
    this.scene.systems.canvas.addEventListener("webglcontextlost", function (event) {
      event.preventDefault();
    }, false);
    this.scene.systems.canvas.addEventListener("webglcontextrestored", function (event) {
      // no way to recover since everything need to be reconstructed
      window.location.reload();
    }, false);

    // prepare groups 
    this.players = this.add.group();   // the player 
    this.blockDoodads = this.add.group(); // blocking doodads
    this.floatDoodads = this.add.group(); // floating doodads
  }

  // createAnimations - to define global animations
  createAnimations() {
    this.createNengAkangAnimations();
  }

  createNengAkangAnimations() {
    let frameRate = 8;
    // neng
    this.anims.create({ 
      key: 'nengDown', 
      frames: this.anims.generateFrameNumbers('nengAkang',{
      start: 0,
      end: 4,
      first: 0,
    }), frameRate: frameRate, repeat: -1 });
    this.anims.create({ 
      key: 'nengUp', 
      frames: this.anims.generateFrameNumbers('nengAkang',{
      start: 5,
      end: 9,
      first: 0,
    }), frameRate: frameRate, repeat: -1 });
    this.anims.create({ 
      key: 'nengRight', 
      frames: this.anims.generateFrameNumbers('nengAkang',{
      start: 10,
      end: 14,
      first: 0,
    }), frameRate: frameRate, repeat: -1 });
    this.anims.create({ 
      key: 'nengLeft', 
      frames: this.anims.generateFrameNumbers('nengAkang',{
      start: 16,
      end: 19,
      first: 0,
    }), frameRate: frameRate, repeat: -1 });

    // akang
    this.anims.create({ 
      key: 'akangDown', 
      frames: this.anims.generateFrameNumbers('nengAkang',{
      start: 20,
      end: 24,
      first: 0,
    }), frameRate: frameRate, repeat: -1 });
    this.anims.create({ 
      key: 'akangUp', 
      frames: this.anims.generateFrameNumbers('nengAkang',{
      start: 25,
      end: 29,
      first: 0,
    }), frameRate: frameRate, repeat: -1 });
    this.anims.create({ 
      key: 'akangRight', 
      frames: this.anims.generateFrameNumbers('nengAkang',{
      start: 30,
      end: 34,
      first: 0,
    }), frameRate: frameRate, repeat: -1 });
    this.anims.create({ 
      key: 'akangLeft', 
      frames: this.anims.generateFrameNumbers('nengAkang',{
      start: 36,
      end: 39,
      first: 0,
    }), frameRate: frameRate, repeat: -1 });
  }

  createSounds() {
  }

  // drawTextLines to draw multiline text centered
  drawTextLines(x, y, arrStr, size) {
    if (size == null) {
      size = 40;
    }
    var offset = 0;
    for (var i = 0; i < arrStr.length; i++) {
      var t = this.add.dynamicBitmapText(x, y + offset, 'leFont', arrStr[i], size);
      t.setOrigin(0.5, 0.5);
      offset += size + 2;
    }

    return y + offset;
  }

  // getWorldTime - get server tick in `ms`
  getWorldTime() {
    return this.worldTick;
  }

  // getWorldDeltaTime - get diff between server time and our local in `ms`
  getWorldDeltaTime() {
    let now = Date.now();
    let world = this.worldStart + this.epochOffset + this.worldTick;
    return now - world;
  }

  // vibrate - to vibrate the screen (if supported by browser)
  vibrate(pattern) {
    if (typeof navigator.vibrate !== "undefined") {
      navigator.vibrate(pattern);
    }
  }

  // resize
  resize(gameSize) {
    const width = gameSize.width;
    const height = gameSize.height;
    this.parent.setSize(width, height);
    this.sizer.setSize(width, height);
    this.updateCamera();
  }

  // updateCamera - to be called when viewport size changed
  updateCamera() {
    const camera = this.cameras.main;
    const x = Math.ceil((this.parent.width - this.sizer.width) * 0.5);
    const y = 0;
    const scaleX = this.sizer.width / GAME_WIDTH;
    const scaleY = this.sizer.height / GAME_HEIGHT;
    if (camera == null) {
      return;
    }
    camera.setViewport(x, y, this.sizer.width, this.sizer.height);
    camera.setZoom(Math.max(scaleX, scaleY));
    camera.centerOn(GAME_WIDTH / 2, GAME_HEIGHT / 2);
  }

  getZoom() {
    return this.cameras.main.zoom;
  }

  createDoodads(player, onClickHandler, onMenuClickHandler) {
    let dynamicContent = this.cache.json.get('content');
    dynamicContent.items.forEach(element => {
        let d = new Doodad(this, element, player);
        if (typeof element.block != "undefined" && element.block) {
            this.blockDoodads.add(d);
        } else {
            this.floatDoodads.add(d);
        }       
        d.onClick = onClickHandler; 
    });
    // here we build the menu system
    let menu = document.querySelector(".menu__content__list");
    dynamicContent.menus.forEach(element => {
      console.log(element);
      let link = document.createElement('li');
      link.innerHTML = element.title;
      link.setAttribute('data-target', element.target);
      link.addEventListener('click', function menuClick(event) {
        // close the menu
        let menu = document.querySelector(".menu");
        menu.classList.toggle("open");
        return onMenuClickHandler(event, element);
      });
      menu.appendChild(link);
    });
  }  
}

export { BaseScene };
