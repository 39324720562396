import Phaser from "phaser";
import { BaseScene } from "./BaseScene";
import { GAME_WIDTH, GAME_HEIGHT, WINDOW_WIDTH, WINDOW_HEIGHT, GlobalConfig } from './GlobalConfig';
import { Player } from "./objects/Player";

class SceneStage extends BaseScene {

  constructor(...args) {
    super(...args);
  }

  create() {
    super.create();
    this.createAnimations();

    // keyboards
    this.keys = this.input.keyboard.createCursorKeys();
    this.keys.RIGHT = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.RIGHT);
    this.keys.LEFT = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.LEFT);
    this.keys.UP = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.UP);
    this.keys.DOWN = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.DOWN);

    // the huge ass background
    this.background = this.add.sprite(0, 0, 'bg');
    this.background.setOrigin(0, 0);

    // neng akang sprite    
    this.p1 = new Player(this, GAME_WIDTH / 2 - 100, GAME_HEIGHT - 200, "neng");
    this.p1.setCollideWorldBounds(true);
    this.p2 = new Player(this, GAME_WIDTH / 2 + 100, GAME_HEIGHT - 200, "akang");
    this.p2.setCollideWorldBounds(true);
    // neng always follow akang
    this.p1.follow(this.p2);

    // akang is 0, neng in 1
    this.players.add(this.p2);
    this.players.add(this.p1);

    // create all doodads - make sure it's aware of p2 and add on doodadclick event handler
    this.createDoodads(this.p2, this.doodadOnClick, this.menuOnClick);

    // global joystick
    this.input.on('pointerdown', this.startJoystick, this);
    this.input.on('pointermove', this.moveJoystick, this);
    this.input.on('pointerup', this.stopJoystick, this);
    this.input.on('pointerout', this.stopJoystick, this);

    // camera bounds - 3192 is the bg size 
    this.cameras.main.setBounds(0, 0, 3193, GAME_HEIGHT);
    this.physics.world.setBounds(0, 0, 3193, GAME_HEIGHT);
    // make sure camera always follow akang
    this.cameras.main.setDeadzone(GAME_WIDTH / 2, GAME_HEIGHT);
    this.cameras.main.startFollow(this.p2, true, 0.05, 0.05);
  }

  menuOnClick(event, element) {    
    let that = window.WalkerGame;
    let dynamicContent = that.cache.json.get('content');
    dynamicContent.items.forEach(item => {
      if (element.target == item.id) {
        let scene = window.WalkerGame.scene.getScene("SceneStage");
        let p1 = scene.players.children.get(0);
        p1.setToGetTo(item.x, item.y + 200, function() {
          console.log("Reaching target element", item);
          scene.game.openLightbox(item.action);
        });          
      }
    });  
  }

  doodadOnClick(doodad, scene, element) {
    // event handler - when a doodad is clicked
    // this is where we:
    //    - set the target position of Player akang
    //    - set the Player akang event handler when akang reach target position
    if (typeof element.action == "undefined") {
      return;
    }

    // get the `akang'
    let p1 = scene.players.children.get(0);
    // akang target position and what to do when reaching that position
    p1.setToGetTo(element.x, element.y + 200, function() {
        console.log("Reaching target element", element);
        scene.game.openLightbox(element.action);
    });


    // game.openLightbox(element.action);
    console.log(doodad, game, element);
  }

  render() {
    // ...
  }

  // Is called 60 times per second
  update(elapsedTime, deltaTime) {
    this.lastClickTick += deltaTime;
    let moveSpeed = 10;

    // get the `akang'
    let p1 = this.players.children.get(0);

    let press = false;

    // players update
    this.players.children.iterate(function (p) {
      if (!p.active) {
        return;
      }
      p.update(elapsedTime, deltaTime);
    });

    if (p1.isWalking) {
      // if akang is in walking state - no need to handle touch or key event 
      return;
    }

    // Touch or Keyboard handler
    if (this.joystickHandle && this.startTouchPos) {
      press = true;
      var referencePoint = new Phaser.Math.Vector2(this.startTouchPos.x, this.startTouchPos.y);
      var joystickPosition = new Phaser.Math.Vector2(this.joystickHandle.x, this.joystickHandle.y);
      var direction = Phaser.Math.Angle.BetweenPoints(referencePoint, joystickPosition);

      var referenceAngle = -Math.PI; // pointing upwards

      if (Math.abs(direction) > 0) {
        var up = Math.round(Math.sin(direction - referenceAngle));
        var down = Math.round(Math.sin(direction - referenceAngle - Math.PI));
        var left = Math.round(Math.cos(direction - referenceAngle));
        var right = Math.round(Math.cos(direction - referenceAngle - Math.PI));

        if (up > 0) {
          p1.moveUp();
        } else if (down > 0) {
          p1.moveDown();
        } else if (left > 0) {
          p1.moveLeft();
        } else if (right > 0) {
          p1.moveRight();
        }
      }
    } else {
      // keyboard for debugging
      if (this.keys.UP.isDown) {
        press = true;
        p1.moveUp();
      } else if (this.keys.DOWN.isDown) {
        press = true;
        p1.moveDown();
      } else if (this.keys.LEFT.isDown) {
        press = true;
        p1.moveLeft();
      } else if (this.keys.RIGHT.isDown) {
        press = true;
        p1.moveRight();
      } else {
        press = false;
      }
    }

    if (!press) {
      p1.moveStop();
    }
  }

  startJoystick(pointer) {
    // record start of joystick origin drag
    this.startTouchPos = { x: pointer.x, y: pointer.y };
    this.joystickHandle = { x: pointer.x, y: pointer.y };
  }

  moveJoystick(pointer) {
    // move 
    if (this.joystickHandle) {
      this.joystickHandle.x = pointer.x;
      this.joystickHandle.y = pointer.y;
    }
  }

  stopJoystick(pointer) {
    // clear joystick
    this.startTouchPos = null;
    this.joystickHandle = null;
  }
}

export { SceneStage };
