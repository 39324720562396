import Phaser from "phaser";
import { GAME_WIDTH, GAME_HEIGHT, GlobalConfig } from './GlobalConfig';
import { BaseScene } from "./BaseScene";

// SceneUnlocker is basically scene that will stop you form entering - you need
// to press play to proceed - this is for inviting user interaction so a `sound` will be enabled 
// for user
class SceneUnlocker extends BaseScene {

  constructor(...args) {
    super(...args);
  }

  create() {
    super.create();
    this.createSounds();

    // bg
    this.bg = this.add.tileSprite(
      GAME_WIDTH / 2, GAME_HEIGHT / 2, GAME_WIDTH, GAME_HEIGHT,
      'bgbg',
      "stars"
    );
    this.planet = this.add.image(GAME_WIDTH / 2, GAME_HEIGHT, 'bgbg', 'planet1');
    this.logImg = this.add.image(GAME_WIDTH / 2, GAME_HEIGHT / 2 - 300, "logo");

    this.drawTextLines(GAME_WIDTH / 2, GAME_HEIGHT / 2, [
      "Tap",
      "To",
      "Start",
      "",
      "Tap",
      "To",
      "Destroy"
    ], 40);

    this.input.on('pointerdown', function (pointer) {
      // tap is pressed - music should be unlocked by now - proceed with the scene
      this.scene.start(GlobalConfig.startScene);
    }, this);
  }

  update() {
    // Add motion to bg tiles
    this.bg.tilePositionX += 10;
  }
}

export { SceneUnlocker };
