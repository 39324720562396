import Phaser from "phaser";
import { GlobalConfig } from './GlobalConfig';
import { BaseScene } from "./BaseScene";

// SceneBoot is for booter - quick load a preloader-bar before we load whole resources
class SceneBoot extends BaseScene {

  constructor(...args) {
    super(...args);
  }

  preload() {
    const assetsUrl = GlobalConfig.assetsUrl;
    this.load.crossOrigin = "anonymous";

    console.log("SceneBoot", assetsUrl);

    // load the small progress bar image
    this.load.image('loadingbar', `${assetsUrl}/preloader-bar.png`);

    // load the bitmap text
    this.load.bitmapFont("leFont", `${assetsUrl}/leFont.png`, `${assetsUrl}/leFont.xml`);
  }

  create() {
    super.create();
    this.scene.start('SceneLoader');
  }
}

export { SceneBoot };
