import Phaser from "phaser";
// import ShakePositionPlugin from 'phaser3-rex-plugins/plugins/shakeposition-plugin.js';
// import VirtualJoystickPlugin from 'phaser3-rex-plugins/plugins/virtualjoystick-plugin.js';
import $ from 'jquery';

// not sure why external works this way
const lity = require("../lightbox/lity.js");

// Base needed
import { SceneBoot } from "./SceneBoot";
import { SceneLoader } from "./SceneLoader";
import { SceneUnlocker } from "./SceneUnlocker";

// The scenes
import { SceneStage } from "./SceneStage";
//import { SceneStageOffline } from "./SceneStageOffline";

import { GlobalConfig, WINDOW_HEIGHT, WINDOW_WIDTH } from './GlobalConfig';

class WalkerGame extends Phaser.Game {

  openLightbox(action) {
    lity(action);
  }
  
  constructor(options) {

    if (typeof options.width != "undefined") {
      GlobalConfig.width = options.width;
    }
    if (typeof options.height != "undefined") {
      GlobalConfig.height = options.height;
    }

    if (typeof options.submitScore != "undefined") {
      GlobalConfig.submitScore = options.submitScore;
    } else {
      GlobalConfig.submitScore = function (score) {
        console.log(score);
      }
    }

    if (typeof options.gameDone != "undefined") {
      GlobalConfig.gameDone = options.gameDone;
    } else {
      GlobalConfig.gameDone = function () {
        console.log("Done");
      }
    }

    if (typeof options.socket != "undefined") {
      GlobalConfig.socket = options.socket;
    }
    if (typeof options.userId != "undefined") {
      GlobalConfig.userId = options.userId;
    }
    if (typeof options.userName != "undefined") {
      GlobalConfig.userName = options.userName;
    }
    if (typeof options.mode != "undefined") {
      GlobalConfig.mode = options.mode;
    }
    if (typeof options.currentScore != "undefined") {
      GlobalConfig.currentScore = options.currentScore;
    }
    if (GlobalConfig.currentScore == null || typeof GlobalConfig.currentScore == "undefined") {
      GlobalConfig.currentScore = 0;
    }

    if (options.mode === "viewer") {
      GlobalConfig.startScene = "ScenePreview";
    }

    console.log(GlobalConfig, WINDOW_WIDTH, WINDOW_HEIGHT);

    // initializer
    super({
      // force WEBGL - for device without WEBGL - buy new device please
      type: Phaser.AUTO,
      autoFocus: true,
      pixelArt: true,
      roundPixels: true,
      backgroundColor: '#CCC',
      scale: {
        mode: Phaser.Scale.RESIZE,
        parent: 'game',
        autoCenter: Phaser.Scale.CENTER_HORIZONTALLY,
        width: '100%',
        height: '100%',

        // clamp
        minHeight: GlobalConfig.minHeight,
        maxHeight: GlobalConfig.maxHeight,
        minWidth: GlobalConfig.minWidth,
        maxWidth: GlobalConfig.maxWidth,
      },
      render: {
        antialiasGL: false,
        pixelArt: true,
      },
      plugins: {
        global: [
          // {
          //   key: 'rexShakePosition',
          //   plugin: ShakePositionPlugin,
          //   start: true
          // }, 
          // {
          //   key: 'rexVirtualJoystick',
          //   plugin: VirtualJoystickPlugin,
          //   start: true
          // },
        ],
      },
      physics: {
        default: "arcade",
        arcade: {
          debug: GlobalConfig.debug
        }
      },
      input: {
        keyboard: {
          target: window
        },
        mouse: {
          target: null,
          capture: true,
        },
        activePointers: 2,
        touch: {
          target: null,
          capture: true,
        },
        smoothFactor: 0,
        gamepad: false,
        windowEvents: true,
      },
      callbacks: {
        postBoot: () => {
          // ...
        },
      },
      audio: {
        disableWebAudio: false,
      },
    });

    // Create a random data generator to be used across the game.
    // with the same seed
    this.rdg = new Phaser.Math.RandomDataGenerator(["lu", "mi", "o"]);
    this.currentScore = GlobalConfig.currentScore;

    this.globalSettings = GlobalConfig;

    // base necessities
    this.scene.add("SceneBoot", new SceneBoot());
    this.scene.add("SceneLoader", new SceneLoader());

    // our game scenes
    this.scene.add("SceneUnlocker", new SceneUnlocker());
    this.scene.add("SceneStage", new SceneStage());
    //this.scene.add("SceneStageOffline", new SceneStageOffline());

    // kitchensinks
    //this.scene.add("SceneSpriteSink", new SceneSpriteSink());

    this.scene.start('SceneBoot');
  }
}

export { WalkerGame };
