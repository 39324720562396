const GAME_WIDTH = 1080;
const GAME_HEIGHT = 1920;
const WINDOW_WIDTH = window.innerWidth;
const WINDOW_HEIGHT = window.innerHeight;

var GlobalConfig = {
    // public assets path
    assetsUrl: "/assets",

    // socket
    socketUrl: null,
    userId: "0",
    userName: "demo",
    socketId: null,

    // sizing
    height: GAME_HEIGHT,
    width: GAME_WIDTH,

    minHeight: GAME_HEIGHT / 4,
    minWidth: GAME_WIDTH / 4,

    maxHeight: GAME_HEIGHT * window.devicePixelRatio,
    maxWidth: GAME_WIDTH * window.devicePixelRatio,

    // default start scene
    startScene: "SceneStage",

    debug: false,
};

/**
 * To get value of `name` param from query string
 * @param string name 
 * @param {*} url 
 * @returns string
 */
function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

// dev env override
if (process.env.NODE_ENV === 'development') {
    GlobalConfig.startScene = "SceneStage"
    GlobalConfig.assetsUrl = "/assets";

    // socket
    GlobalConfig.socketUrl = null;
    GlobalConfig.userId = "ffcbex";
    GlobalConfig.userName = "demo";
}

// get the scene query string for dev purpose
let scene = getParameterByName('scene');
if (scene !== null) {
    GlobalConfig.startScene = scene;
}

let debug = getParameterByName('debug');
if (debug !== null) {
    GlobalConfig.debug = (debug == "1") ? true : false;
}


export { GAME_WIDTH, GAME_HEIGHT, WINDOW_WIDTH, WINDOW_HEIGHT, GlobalConfig };
